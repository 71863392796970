import React from 'react';
import {Navigate, useParams, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import ServiceItem from "../components/ServiceItem";
import Player from "../components/Player";

export default function AlbumView({data}) {
    const {id} = useParams();
    const location = useLocation();

    const album = data.find(a => a.id === id);

    if (album) {
        album.cover = `/static/data/artworks/album.${album.id}.jpg`;
        return (
            <>
                <Helmet>
                    <title>Shlygly - {album.title}</title>
                    <meta property="og:title" content={album.title}/>
                    <meta property="og:description" content={`${album.title}, an album of Shlygly.`}/>
                    <meta property="og:url" content={`https://shlygly.com${location.pathname}`}/>
                    <meta property="og:site_name" content="Shlygly"/>
                    <meta property="og:type" content="music.album"/>
                    <meta property="og:image" content={`https://shlygly.com${album.cover}`}/>
                    <meta property="music:musician" content="http://open.spotify.com/artist/323E6r2yer8yW3xZIpfdkY"/>
                    <meta property="music:release_date" content={album.releaseDate}/>
                </Helmet>
                <div className="background-attachment"
                     style={{
                         backgroundImage: `url(${album.cover})`
                     }}
                />
                <main
                    style={{
                        backgroundColor: album.backgroundColor
                    }}
                >
                    <img
                        className="cover"
                        src={album.cover}
                        alt={"Cover of " + album.title}
                    />
                    <h1>{album.title}</h1>
                    <h2>Services</h2>
                    <section className="services">
                        {
                            album.services.map(s =>
                                <ServiceItem key={s.src} {...s} />
                            )
                        }
                    </section>
                    {
                        album.players &&
                        <>
                            <h2>Player</h2>
                            {
                                album.players.map((playerData, index) => (
                                    <Player key={index} title={album.title} {...playerData} />
                                ))
                            }
                        </>
                    }
                </main>
            </>
        )
    } else {
        return <Navigate to="/"/>
    }
}
