import React from 'react';
import {Navigate, useLocation, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import Player from "../components/Player";
import Alert from "../components/Alert";
import download from "../ressources/images/services/download.svg";

export default function CompooseView({data}) {
    const {id} = useParams();
    const location = useLocation();

    const compoose = data.find(c => c.type === "compoose" && c.id === id);

    if (compoose) {
        compoose.screenshot = `/static/data/artworks/challenge.${compoose.id}.jpg`;
        return (
            <>
                <Helmet>
                    <title>Shlygly - {compoose.title} (Compoose Challenge)</title>
                    <meta property="og:title" content={compoose.title}/>
                    <meta property="og:description" content={`Compoose challenge of ${compoose.releaseDate}.`}/>
                    <meta property="og:url" content={`https://shlygly.com${location.pathname}`}/>
                    <meta property="og:site_name" content="Shlygly"/>
                    <meta property="og:type" content="music.song"/>
                    <meta property="og:image" content={`https://shlygly.com${compoose.screenshot}`}/>
                    <meta property="music:musician" content="http://open.spotify.com/artist/323E6r2yer8yW3xZIpfdkY"/>
                    <meta property="music:release_date" content={compoose.releaseDate}/>
                </Helmet>
                <div className="background-attachment"
                     style={{
                         backgroundImage: `url(${compoose.screenshot})`
                     }}
                />
                <main
                    style={{
                        backgroundColor: compoose.backgroundColor
                    }}
                >
                    <Alert
                        title="Compoose challenge"
                        text="Once a month, a random video-game screenshot is chosen by
                        a bot. We have 1h to produce a track that fit the theme of the picture."
                        footer={
                            <a href="https://discord.gg/y5BVg7Tm4K" target="_blank" rel="noreferrer">
                                More info / Join the challenge
                            </a>
                        }
                    />
                    <h2>Screenshot <small>(Compoose {compoose.releaseDate})</small> :</h2>
                    <img
                        className="screenshot"
                        src={compoose.screenshot}
                        alt={"Screenshot of the Compoose challenge from " + compoose.releaseDate}
                    />
                    <h1>{compoose.title}</h1>
                    <section className="services">
                        <Player
                            type="h5"
                            src={`/static/data/tracks/${compoose.filename}`}
                        />
                        <a href={`/static/data/tracks/${compoose.filename}`} target="_blank"
                           rel="noreferrer" className="service-item" download>
                            <div className="img-box">
                                <img src={download} alt="Free download"/>
                            </div>
                        </a>
                    </section>
                </main>
            </>
        )
    } else {
        return <Navigate to="/"/>
    }
}
