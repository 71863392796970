import React, {useState, useEffect, useRef} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {Helmet} from "react-helmet";
import './App.css';
import artist from "./ressources/images/artist.jpg";
import Menu from "./components/Menu";
import AlbumView from "./view/AlbumView";
import RemixView from "./view/RemixView";
import Loading from "./components/Loading";
import CompooseView from "./view/CompooseView";
import MiniremixView from "./view/MiniremixView";

function App() {
    const [isContentLoading, setIsContentLoading] = useState(true);
    const [showLoader, setShowLoader] = useState(true);
    const [menuItems, setMenuItems] = useState([]);
    const [albums, setAlbums] = useState([]);
    const [remixes, setRemixes] = useState([]);
    const [challenges, setChallenges] = useState([]);

    const topRef = useRef(null);

    const scrollToPage = () => topRef.current.scrollIntoView({"behavior": "smooth"});

    useEffect(() => {
        fetch("/static/data/content.json")
            .then(resp => resp.json())
            .then(data => {
                setMenuItems([
                    {
                        "label": "Albums",
                        "external": false,
                        "urlPrefix": "/album/",
                        "items": data.albums
                            .map(({id, title, releaseDate}) => ({id, title, releaseDate}))
                            .sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate))
                    },
                    {
                        "label": "Remixes",
                        "external": false,
                        "urlPrefix": "/remix/",
                        "items": data.remixes
                            .map(({id, title, artist, releaseDate}) => ({id, title: `${artist} - ${title}`, releaseDate}))
                            .sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate))
                    },
                    {
                        "label": "Challenges - Compoose",
                        "external": false,
                        "urlPrefix": "/challenge/compoose/",
                        "items": data.challenges
                            .filter(({type}) => type === "compoose")
                            .map(({id, title, releaseDate}) => ({id, title, releaseDate}))
                            .sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate))
                    },
                    {
                        "label": "Challenges - Mini remix",
                        "external": false,
                        "urlPrefix": "/challenge/miniremix/",
                        "items": data.challenges
                            .filter(({type}) => type === "miniremix")
                            .map(({id, title, artist, genres, releaseDate}) => ({id, title: `${artist} - ${title}`, releaseDate}))
                            .sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate))
                    },
                    {
                        "external": true,
                        "items": data.socials
                    }
                ]);
                setAlbums(data.albums.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate)));
                setRemixes(data.remixes.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate)));
                setChallenges(data.challenges.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate)));
                setIsContentLoading(false);
                setTimeout(() => setShowLoader(false), 1000);
            });
    }, []);

    useEffect(() => {
        if (!isContentLoading) {
            scrollToPage();
        }
    }, [isContentLoading]);

    return (
        <BrowserRouter>
            <Helmet>
                <title>Shlygly</title>
                <meta property="og:title" content="Shlygly"/>
                <meta property="og:description" content="Discover & listen to Shlygly's music !"/>
                <meta property="og:url" content="https://shlygly.com/"/>
                <meta property="og:site_name" content="Shlygly"/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content={`https://shlygly.com${artist}`}/>
                <meta property="music:musician" content="http://open.spotify.com/artist/323E6r2yer8yW3xZIpfdkY"/>
            </Helmet>
            {
                (isContentLoading || showLoader) && <Loading fadeOut={!isContentLoading}/>
            }
            {
                !isContentLoading && (
                    <>
                        <Menu groups={menuItems} onMenuClick={scrollToPage}/>
                        <div ref={topRef}/>
                        <Routes>
                            <Route path="/album/:id" element={<AlbumView data={albums}/>}/>
                            <Route path="/remix/:id" element={<RemixView data={remixes}/>}/>
                            <Route path="/challenge/compoose/:id" element={<CompooseView data={challenges}/>}/>
                            <Route path="/challenge/miniremix/:id" element={<MiniremixView data={challenges}/>}/>
                            <Route path="*" element={<Navigate to={`/album/${albums[0].id}`}/>}/>
                        </Routes>
                    </>
                )
            }
        </BrowserRouter>
    );
}

export default App;
