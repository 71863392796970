import React from 'react';
import {Navigate, useLocation, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import Player from "../components/Player";
import Alert from "../components/Alert";
import download from "../ressources/images/services/download.svg";

export default function MiniremixView({data}) {
    const {id} = useParams();
    const location = useLocation();

    const miniremix = data.find(c => c.type === "miniremix" && c.id === id);

    if (miniremix) {
        miniremix.screenshot = `/static/data/artworks/challenge.${miniremix.id}.jpg`;
        return (
            <>
                <Helmet>
                    <title>{miniremix.artist} - {miniremix.title} (Shlygly MiniRemix)</title>
                    <meta property="og:title"
                          content={`${miniremix.artist} - ${miniremix.title} (Shlygly MiniRemix)`}/>
                    <meta property="og:description"
                          content={`Shlygly's mini remix challenge of ${miniremix.title} by ${miniremix.artist}.`}/>
                    <meta property="og:url" content={`https://shlygly.com${location.pathname}`}/>
                    <meta property="og:site_name" content="Shlygly"/>
                    <meta property="og:type" content="music.song"/>
                    <meta property="og:image" content={`https://shlygly.com${miniremix.screenshot}`}/>
                    <meta property="music:musician" content="http://open.spotify.com/artist/323E6r2yer8yW3xZIpfdkY"/>
                    <meta property="music:release_date" content={miniremix.releaseDate}/>
                </Helmet>
                <main className="default-background"
                      style={{
                          backgroundColor: miniremix.backgroundColor
                      }}
                >
                    <Alert
                        title="Mini remix challenge"
                        text="Sometimes I ask for popular, shameful or cliché music to remix. The aim is to find a style as different from the original as possible."
                    />
                    <img
                        className="cover"
                        src={miniremix.screenshot}
                        alt={"Cover of the mini remix challenge of " + miniremix.title + " by " + miniremix.artist}
                    />
                    <div className="pills">
                        {miniremix.genres.map(g => (
                            <span style={{
                                backgroundColor: miniremix.backgroundColor
                            }}>{g}</span>
                        ))}
                    </div>
                    <h1>{miniremix.artist} - {miniremix.title} (Shlygly MiniRemix)</h1>
                    <section className="services">
                        <Player
                            type="h5"
                            src={`/static/data/tracks/${miniremix.filename}`}
                        />
                        <a href={`/static/data/tracks/${miniremix.filename}`} target="_blank"
                           rel="noreferrer" className="service-item" download>
                            <div className="img-box">
                                <img src={download} alt="Free download"/>
                            </div>
                        </a>
                    </section>
                </main>
            </>
        )
    } else {
        return <Navigate to="/"/>
    }
}
