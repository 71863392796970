import React from 'react';
import './Menu.css';
import logo from "../ressources/images/logo.svg";
import {Link} from "react-router-dom";

export default function Menu({groups, onMenuClick}) {
    return (
        <nav className="menu">
            <a href="/">
                <img className="menu-logo" src={logo} alt="Shlygly's logo"/>
            </a>
            <div className="menu-layout">
                {
                    groups.map(({label, external, urlPrefix, items}, groupIndex) => (
                        <section className="submenu" key={groupIndex}>
                            <hr/>
                            {label && <span className="subtitle">{label}</span>}
                            {
                                items.map(item => {
                                    if (external) {
                                        const {label, url} = item;
                                        return (
                                            <a href={url} target="_blank" rel="noreferrer" key={label}>
                                                {label}
                                            </a>
                                        )
                                    } else {
                                        const {id, title, releaseDate} = item;
                                        return (
                                            <Link to={(urlPrefix ? urlPrefix : "") + id} key={id} onClick={onMenuClick}>
                                                {title} <small>{new Date(releaseDate).getFullYear()}</small>
                                            </Link>
                                        );
                                    }
                                })
                            }
                        </section>
                    ))
                }
            </div>
        </nav>
    )
}
