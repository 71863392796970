import React from 'react';
import './Alert.css';
import info from '../ressources/images/icons/info.svg';

const Alert = ({title, text, footer}) => {
    return (
        <div className="alert">
            <img className="icon" src={info} alt="Alert icon"/>
            <h3 className="title">{title}</h3>
            <p className="body">{text}</p>
            <div className="footer">{footer}</div>
        </div>
    );
};

export default Alert;
