import React from 'react';
import amazon from '../ressources/images/services/amazon.svg';
import apple from '../ressources/images/services/apple.svg';
import deezer from '../ressources/images/services/deezer.svg';
import metapop from '../ressources/images/services/metapop.svg';
import sevenDigital from '../ressources/images/services/7digital.svg';
import shazam from '../ressources/images/services/shazam.svg';
import soundcloud from '../ressources/images/services/soundcloud.svg';
import spotify from '../ressources/images/services/spotify.svg';
import youtube from '../ressources/images/services/youtube.svg';

const services = {
    "7digital": {"icon": sevenDigital, "name": "7Digital", "action": "Find on"},
    "amazon": {"icon": amazon, "name": "Amazon", "action": "Buy on"},
    "apple": {"icon": apple, "name": "Apple Music", "action": "Listen on"},
    "deezer": {"icon": deezer, "name": "Deezer", "action": "Listen on"},
    "metapop": {"icon": metapop, "name": "Metapop", "action": "Listen on"},
    "shazam": {"icon": shazam, "name": "Shazam", "action": "Find on"},
    "soundcloud": {"icon": soundcloud, "name": "Soundcloud", "action": "Listen on"},
    "spotify": {"icon": spotify, "name": "Spotify", "action": "Listen on"},
    "youtube": {"icon": youtube, "name": "Youtube", "action": "Listen on"},
};

export default function ServiceItem({src, url}) {
    const service = services[src];

    return (
        <a href={url} target="_blank" rel="noreferrer" className="service-item">
            {
                service &&
                <>
                    <span>{service.action}</span>
                    <div className="img-box">
                        <img src={service.icon} alt={service.name}/>
                    </div>
                </>
            }
        </a>
    )
}
