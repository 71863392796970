import React from 'react';
import H5AudioPlayer from "react-h5-audio-player";
import 'react-h5-audio-player/lib/styles.css';

const Player = ({type, title, src, href}) => {
    if (type === "soundcloud") {
        return (
            <>
                <iframe width="100%" height="166" scrolling="no" frameBorder="no" allow="autoplay"
                        title={`Play ${title} on Soundcloud`} src={src}/>
                <div
                    style={{
                        fontSize: "10px",
                        color: "#cccccc",
                        lineBreak: "anywhere",
                        wordBreak: "normal",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
                        fontWeight: "100"
                    }}>
                    <a href="https://soundcloud.com/shlyglypotassin" title="Shlygly" target="_blank" rel="noreferrer"
                       style={{color: "#cccccc", textDecoration: "none"}}>Shlygly</a> · <a
                    href={href} title={title} target="_blank"
                    rel="noreferrer"
                    style={{color: "#cccccc", textDecoration: "none"}}>{title}</a></div>
            </>
        );
    } else if (type === "spotify") {
        return (
            <iframe src={src} width="100%" frameBorder="0" allowFullScreen=""
                    style={{"maxWidth": "1000px"}}
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture">
            </iframe>
        );
    } else if (type === "h5") {
        return (
            <H5AudioPlayer
                src={src}
                autoPlayAfterSrcChange={false}
            />
        )
    } else {
        return "";
    }
};

export default Player;
