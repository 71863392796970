import React from 'react';
import './Loading.css';

const Loading = ({fadeOut}) => {
    return (
        <div className="loading" hidden={fadeOut}>
            <div className="animation"/>
            <p>Loading...</p>
        </div>
    );
};

export default Loading;
