import React from 'react';
import {Navigate, useLocation, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import ServiceItem from "../components/ServiceItem";
import Player from "../components/Player";

export default function RemixView({data}) {
    const {id} = useParams();
    const location = useLocation();

    const remix = data.find(a => a.id === id);

    if (remix) {
        remix.cover = `/static/data/artworks/remix.${remix.id}.jpg`;
        return (
            <>
                <Helmet>
                    <title>Shlygly - {remix.title} (by {remix.artist})</title>
                    <meta property="og:title" content={`${remix.title} (Shlygly Remix)`}/>
                    <meta property="og:description" content={`${remix.title} by ${remix.artist} remixed by Shlygly.`}/>
                    <meta property="og:url" content={`https://shlygly.com${location.pathname}`}/>
                    <meta property="og:site_name" content="Shlygly"/>
                    <meta property="og:type" content="music.song"/>
                    <meta property="og:image" content={`https://shlygly.com${remix.cover}`}/>
                    <meta property="music:musician" content="http://open.spotify.com/artist/323E6r2yer8yW3xZIpfdkY"/>
                    <meta property="music:release_date" content={remix.releaseDate}/>
                </Helmet>
                <div className="background-attachment"
                     style={{
                         backgroundImage: "url(" + remix.cover + ")"
                     }}
                />
                <main
                    style={{
                        backgroundColor: remix.backgroundColor
                    }}
                >
                    <img
                        className="cover"
                        src={remix.cover}
                        alt={`Cover of the remix of "${remix.title}" for "${remix.artist}"`}
                    />
                    <h1>{`${remix.artist} - ${remix.title} (Shlygly Remix)`}</h1>
                    <h2>Services</h2>
                    <section className="services">
                        {
                            remix.services.map(s =>
                                <ServiceItem {...s} />
                            )
                        }
                    </section>
                    {
                        remix.players &&
                        <>
                            <h2>Player</h2>
                            {
                                remix.players.map((playerData, index) => (
                                    <Player key={index} title={remix.artist + " - " + remix.title + " (Shlygly Remix)"} {...playerData} />
                                ))
                            }
                        </>
                    }
                </main>
            </>
        )
    } else {
        return <Navigate to="/"/>
    }
}
